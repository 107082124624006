<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Clinics</div>
          </template>
          <h1 class="count">{{clinics}}</h1>
        </vs-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" vs-sm="3" vs-xs="12">
        <vs-card class="m-2">
          <template v-slot:header>
            <div class="heading">Total Patients</div>
          </template>
          <h1 class="count">{{patients}}</h1>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      clinics: 0,
      patients: 0
    };
  },
  methods: {
    ...mapActions("doctor", ["getDoctorDashboardData"]),
    getData() {
      const self = this;
      this.getDoctorDashboardData().then(res => {
        self.clinics = res.data.data.clinics;
        self.patients = res.data.data.patients;
      });
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped>
.heading {
  font-size: large;
  font-weight: 600;
  color: #000;
}
.count {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
}
</style>
